<template>
  <AddMarketsModalPerPlayer
    v-if="isAddMarketsModalActive"
    :player-name="playerName"
    :player-id="data.playerId"
    :team-label="teamLabel"
    :is-visible="isAddMarketsModalActive"
    :available-markets="availableMarkets"
    :submitted-markets="marketsInStore"
    no-close-btn
    @onAddSelectedMarkets="onAddSelectedMarkets"
    @onModalClose="onModalClose"
  />
  <div
    class="markets-per-player-single"
    :class="{'player-highlighted': isPlayerHighlighted }"
  >
    <div class="info-header">
      <div
        v-if="isPlayerMarketActionsDisabled"
        @click.stop
        class="markets-per-player-actions-disabled-layer"
      />
      <div
        class="player-info"
        @click="togglePlayerForMarketsModal"
      >
        <Checkbox
          @click="togglePlayerForMarketsModal"
          :model-value="isPlayerSelectedForMarketsModal"
        />
        <span :title="playerMarketInfo?.playerIndex">{{ playerName }}</span>
        <span class="dot-space">&bull;</span>
        <Tooltip
          :text="isPlayerMapped ? 'Player is mapped for projections' : 'Player is not mapped for projections'"
          right
        >
          <Icon
            :name="isPlayerMapped ? 'link-not-broken' : 'link-broken'"
            :class="['projections-mapping-icon', { 'is-mapped': isPlayerMapped }]"
          />
        </Tooltip>
      </div>
      <div
        class="player-actions"
      >
        <div
          class="player-actions-btn"
          @click="togglePlayerStatsActive"
        >
          <Icon :name="playerStatsActive ? 'chevron-down' : 'chevron-right'" />
          <p v-if="!playerStatsActive">
            Show stats
          </p>
          <p v-else>
            Hide stats
          </p>
        </div>
        <div
          v-if="!isPlayerVoid"
          class="player-actions-btn"
          @click="addMarkets"
        >
          <Icon name="edit" />
          <p>Edit market list</p>
        </div>
        <div
          v-if="!isPlayerVoid && hasSubmittedMarkets"
          class="player-actions-btn"
          :class="{ 'suspend-markets__active': !data.isSuspended }"
          @click="togglePlayerSuspend"
        >
          <Icon :name="data.isSuspended ? 'play' : 'pause'" />
          <p>
            {{ data.isSuspended ? 'Unsuspend markets' : 'Suspend markets' }}
          </p>
        </div>
      </div>
    </div>
    <FootballPlayerStats
      v-if="sportId === footballId && playerStatsActive"
      :stats="playerMarketInfo.stats"
    />
    <BasketballPlayerStats
      v-if="sportId === basketballId && playerStatsActive"
      :stats="playerMarketInfo.stats"
      :competition-type="competitionType"
    />
    <BaseballPlayerStats
      v-if="sportId === baseballId && playerStatsActive"
      :stats="playerMarketInfo.stats"
    />
    <div class="markets-data">
      <div
        v-if="playerHasMarkets"
        class="markets-per-player-list"
      >
        <div
          class="market-item"
          :class="{
            'suspended': market.suspended,
            'void-player-market': isPlayerVoid || market.voided,
          }"
          v-for="market in playerMarkets"
          :key="market.marketCode"
        >
          <div class="market-info">
            <span>{{ market.marketName }}</span>
            <div
              v-if="!market.isLocked && !isPlayerVoid"
              class="suspend-btn"
              :class="{ 'suspend-active': !market.suspended }"
              @click="toggleMarketSuspend(market)"
            >
              <Icon :name="market.suspended ? 'play-circle' : 'pause-circle'" />
            </div>
          </div>
          <div
            v-if="showMarketPrices(market)"
            class="market-prices"
          >
            <!-- MAIN LINE -->
            <div class="market-price">
              <NumberInput
                class="number-input"
                v-if="getMainLine(market)"
                :model-value="getMainLine(market)"
                @update:modelValue="updateMainLine($event, market)"
                :readonly="isPricesReadonly"
                :number-of-decimals="1"
                :max="700"
                :is-error="market.isMainLineInvalid"
              />
            </div>
            <!-- OVER SELECTION -->
            <div class="market-price">
              <NumberInput
                class="number-input"
                v-if="showMarketOverSelection(market)"
                :model-value="getMarketOverSelection(market)"
                @update:modelValue="updateMarketOverUnderSelection($event, market)"
                :readonly="isPricesReadonly"
                :number-of-decimals="2"
                :is-error="isOverSelectionError(market)"
              />
            </div>
          </div>
          <div
            v-if="showYesSelectionMarketPrices(market)"
            class="market-prices"
          >
            <!-- SCORE INDEX -->
            <div class="market-price">
              <NumberInput
                class="number-input"
                v-if="getScoreIndex(market)"
                :model-value="getScoreIndex(market)"
                readonly
              />
            </div>
            <!-- YES SELECTION -->
            <div class="market-price">
              <NumberInput
                class="number-input"
                v-if="showMarketYesSelection(market)"
                :model-value="getMarketYesSelection(market)"
                @update:modelValue="updateMarketYesNoSelection($event, market)"
                :readonly="isPricesReadonly"
                :number-of-decimals="2"
                :is-error="isMarketYesSelectionError(market)"
              />
            </div>
          </div>
          <div
            v-if="showPointBands(market)"
            class="point-bands"
          >
            <!-- MAIN LINE -->
            <div class="point-band">
              <NumberInput
                class="number-input"
                v-if="getMainLine(market)"
                :model-value="getMainLine(market)"
                @update:modelValue="updateMainLine($event, market)"
                readonly
                :number-of-decimals="1"
              />
            </div>
            <!-- POINT BANDS -->
            <div
              v-for="pointBand in getPointBands(market)"
              :key="pointBand.lowerBound"
              class="point-band"
            >
              <div class="point-band-label">
                {{ pointBand.label }}
              </div>
              <NumberInput
                class="number-input"
                :model-value="getSelectionPrice(pointBand.price)"
                @update:modelValue="updatePointBandSelection($event, market, pointBand)"
                readonly
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import {
  filter, map, find, isNaN,
  orderBy, cloneDeep, includes,
} from 'lodash';
import { ID as footballId } from '@/services/helpers/football';
import { ID as basketballId } from '@/services/helpers/basketball';
import Icon from '@/components/common/Icon';
import NumberInput from '@/components/common/NumberInput';
import Checkbox from '@/components/common/Checkbox';
import MarketMapper from '@/services/helpers/market-mapper';
import { formatPlayerName } from '@/services/helpers/player-setup-mapper';
import Event from '@/services/helpers/event';
import sportIds from '@/services/helpers/sports';
import Tooltip from '@/components/common/Tooltip';
import AddMarketsModalPerPlayer from './modals/AddMarketsModalPerPlayer';
import FootballPlayerStats from './stats/FootballPlayerStats';
import BasketballPlayerStats from './stats/BasketballPlayerStats';
import { getBaseballPlayerMarketCodesByType, getAFMarketCodesByType } from '@/components/player-setup/params-helper';
import BaseballPlayerStats from './stats/BaseballPlayerStats';

const {
  BASEBALL_ID,
  FOOTBALL_ID,
} = sportIds;

export default {
  props: {
    playerMarketInfo: {
      type: Object,
      default: () => {},
    },
    teamLabel: {
      type: String,
      default: '',
    },
    sportId: {
      type: String,
      default: '',
    },
    competitionType: {
      type: String,
      default: 'NBA',
    },
  },
  components: {
    Icon,
    AddMarketsModalPerPlayer,
    NumberInput,
    FootballPlayerStats,
    BasketballPlayerStats,
    BaseballPlayerStats,
    Checkbox,
    Tooltip,
  },
  setup(props) {
    const store = useStore();
    const isAddMarketsModalActive = ref(false);

    const data = computed(() => props.playerMarketInfo);
    const eventData = computed(() => store.getters.playerSetupData || {});
    const isPricesReadonly = computed(() => !Event.isPrematch(eventData.value)
      || (eventData.value.sportId === BASEBALL_ID)
      || !data.value.playerLocked
      || (eventData.value.sportId === FOOTBALL_ID && !data.value.isQuarterback));
    const playerName = computed(() => formatPlayerName(data.value.playerName));
    const playerHasMarkets = computed(() => !!data.value?.markets?.length);
    const playerMarkets = computed(() => MarketMapper.sortPlayerMarkets(data.value?.markets, props.sportId));
    const isPlayerVoid = computed(() => data.value.playerLocked && !data.value.lineup);
    const marketsInStore = computed(() => data.value?.markets);
    const hasSubmittedMarkets = computed(() => !!filter(marketsInStore.value, (market) => !market.isLocked)?.length);
    const isPlayerSelectedForMarketsModal = computed(() => !!find(store.getters.playersForMarketsPopupList, (playerId) => playerId === data.value.playerId));
    const isPlayerHighlighted = computed(() => data.value?.playerId === store.getters.highlightedPlayer);
    const playerStatsActive = ref(false);
    const checkIsMarketDisabled = (marketCode) => {
      const addedMarket = find(data.value?.markets, { marketCode });
      if (!addedMarket) return false;
      return !addedMarket.isLocked;
    };
    const availableMarkets = computed(() => {
      let marketsFiltered = [];
      if (eventData.value.sportId === BASEBALL_ID) {
        marketsFiltered = getBaseballPlayerMarketCodesByType({
          markets: store.getters.availableMarkets,
          isPitcher: props.playerMarketInfo?.isPitcher,
          isHitter: props.playerMarketInfo?.isHitter,
        });
      } else if (eventData.value.sportId === FOOTBALL_ID) {
        marketsFiltered = getAFMarketCodesByType({
          markets: store.getters.availableMarkets,
          availableMarketsPlayerTypes: store.getters.availableMarketsPlayerTypes,
          isQuarterback: props.playerMarketInfo?.isQuarterback,
          isOffensiveNonQB: props.playerMarketInfo?.isOffensiveNonQB,
          isKicker: props.playerMarketInfo?.isKicker,
        });
      } else {
        marketsFiltered = store.getters.availableMarkets;
      }
      const markets = map(marketsFiltered, (market) => ({
        value: market.marketCode,
        label: market.marketName,
        disabled: checkIsMarketDisabled(market.marketCode),
      }));
      return markets;
    });
    const isPlayerMarketActionsDisabled = computed(() => store.getters.playerSetupInferActive);

    const getSelectionPrice = (price) => {
      let value = price.probability * 100;
      if (isNaN(value)) return null;

      value = value % 1 !== 0
        ? parseFloat(value.toFixed(1))
        : parseInt(value, 10);

      return value;
    };

    const getMarketOverSelection = (market) => {
      const price = find(market.selections, { selectionType: { selectionCode: 'OVER' } })?.price;
      if (!price) return null;

      return getSelectionPrice(price);
    };

    const isOverSelectionError = (market) => {
      const overSelection = find(market.selections, { selectionType: { selectionCode: 'OVER' } });

      return overSelection?.isError;
    };

    const getMarketYesSelection = (market) => {
      const price = find(market.selections, { selectionType: { selectionCode: 'YES' } })?.price;
      if (!price) return null;

      return getSelectionPrice(price);
    };

    const isMarketYesSelectionError = (market) => {
      const yesSelection = find(market.selections, { selectionType: { selectionCode: 'YES' } });

      return yesSelection.isError;
    };

    const getMainLine = (market) => {
      if (!market?.marketSummary?.isMainLine) return null;
      const mainLine = market?.marketSummary?.mainLine;

      return mainLine;
    };

    const getScoreIndex = (market) => market?.params?.SCORE_INDEX;

    const showPointBandLabel = (pointBand, hasUpperBound = true) => (
      (pointBand.lowerBound
        || pointBand.lowerBound === 0)
      && hasUpperBound ? pointBand.upperBound : true
    );

    const getPointBands = (market) => {
      if (!market.selections?.length) return null;
      let pointBands = map(market.selections, (selection) => ({
        selectionId: selection.selectionId,
        selectionCode: selection.selectionType?.selectionCode,
        lowerBound: selection.selectionType?.params?.LOWER_BOUND,
        upperBound: selection.selectionType?.params?.UPPER_BOUND,
        price: selection.price,
      }));

      pointBands = orderBy(pointBands, ['lowerBound']);

      // Setting point band labels
      pointBands = map(pointBands, (pointBand) => ({
        ...pointBand,
        label: showPointBandLabel(pointBand) ? `${pointBand.lowerBound} to ${pointBand.upperBound}` : null,
      }));

      // Setting label for lowest lower bound
      const lowest = pointBands[0];
      lowest.label = showPointBandLabel(lowest) ? `${lowest?.lowerBound}-${lowest?.upperBound}` : null;

      // Setting label for highest lower bound
      const highest = pointBands[pointBands.length - 1];
      highest.label = showPointBandLabel(highest, false) ? `${highest?.lowerBound}+` : null;

      return pointBands;
    };
    const showMarketOverSelection = (market) => getMarketOverSelection(market) || getMarketOverSelection(market) === 0;
    const showMarketYesSelection = (market) => getMarketYesSelection(market) || getMarketYesSelection(market) === 0;
    const showPointBands = (market) => market.marketCode === 'PLAYER_POINT_BANDS' && (getPointBands(market) || getMainLine(market));
    const showMarketPrices = (market) => (showMarketOverSelection(market) || getMainLine(market)) && !showPointBands(market) && !showMarketYesSelection(market);
    const showYesSelectionMarketPrices = (market) => (showMarketYesSelection(market) || getScoreIndex(market)) && !showPointBands(market) && !showMarketOverSelection(market);

    const onModalClose = () => { isAddMarketsModalActive.value = false; };

    const addMarkets = () => {
      isAddMarketsModalActive.value = true;
    };

    const toggleMarketSuspend = (market) => {
      const storeAction = market.suspended
        ? 'unsuspendPlayerMarkets' : 'suspendPlayerMarkets';
      store.dispatch(storeAction, {
        playerId: props.playerMarketInfo.playerId,
        teamLabel: props.teamLabel,
        marketCodes: [
          market,
        ],
      });
    };

    const togglePlayerSuspend = () => {
      const suspended = props.playerMarketInfo.isSuspended;
      const storeAction = suspended
        ? 'unsuspendPlayerMarkets'
        : 'suspendPlayerMarkets';

      const markets = suspended
        ? props.playerMarketInfo.markets
        : filter(props.playerMarketInfo.markets, { suspended: false });

      store.dispatch(storeAction, {
        playerId: props.playerMarketInfo.playerId,
        teamLabel: props.teamLabel,
        marketCodes: markets,
      });
    };

    const togglePlayerStatsActive = () => { playerStatsActive.value = !playerStatsActive.value; };

    const onAddSelectedMarkets = () => {
      isAddMarketsModalActive.value = false;
    };

    const togglePlayerForMarketsModal = () => {
      store.dispatch('togglePlayerForMarketsPopupList', data.value.playerId);
    };

    const updateMarket = (updatedMarket) => {
      store.dispatch('updatePlayerMarket', {
        playerId: props.playerMarketInfo.playerId,
        teamLabel: props.teamLabel,
        updatedMarket,
      });
    };

    const updateMainLine = (newVal, market) => {
      const updatedMarket = cloneDeep(market);
      if (newVal) {
        updatedMarket.params.LINE = parseFloat(newVal);
        delete updatedMarket.isError;
        delete updatedMarket.isMainLineInvalid;
      } else {
        updatedMarket.isError = true;
        updatedMarket.isMainLineInvalid = true;
      }
      updateMarket(updatedMarket);
    };
    const updateMarketOverUnderSelection = (newVal, market) => {
      const updatedMarket = cloneDeep(market);
      const overSelection = find(updatedMarket.selections, { selectionType: { selectionCode: 'OVER' } });
      const underSelection = find(updatedMarket.selections, { selectionType: { selectionCode: 'UNDER' } });
      if (!overSelection || !underSelection) return;
      if (newVal) {
        const newValFormatted = parseFloat(newVal) / 100;
        overSelection.price.probability = newValFormatted;
        underSelection.price.probability = 1 - newValFormatted;
        delete updateMarket.isError;
        delete overSelection.isError;
      } else {
        updatedMarket.isError = true;
        overSelection.isError = true;
      }
      updateMarket(updatedMarket);
    };

    const updateMarketYesNoSelection = (newVal, market) => {
      const updatedMarket = cloneDeep(market);
      const yesSelection = find(updatedMarket.selections, { selectionType: { selectionCode: 'YES' } });
      const noSelection = find(updatedMarket.selections, { selectionType: { selectionCode: 'NO' } });
      if (!yesSelection || !noSelection) return;
      if (newVal) {
        const newValFormatted = parseFloat(newVal) / 100;
        yesSelection.price.probability = newValFormatted;
        noSelection.price.probability = 1 - newValFormatted;
        delete updatedMarket.isError;
        delete yesSelection.isError;
      } else {
        updatedMarket.isError = true;
        yesSelection.isError = true;
      }
      updateMarket(updatedMarket);
    };

    const updatePointBandSelection = (newVal, market, pointBand) => {
      const updatedMarket = cloneDeep(market);
      const pointBandSelection = find(updatedMarket.selections, { selectionId: pointBand.selectionId });
      if (!pointBandSelection) return;
      pointBandSelection.price.probability = parseFloat(newVal);
      updateMarket(updatedMarket);
    };

    const playerSetupMappedPlayersForProjections = computed(() => map(store.getters.playerSetupMappedPlayersForProjections, (player) => player.playerId));
    const isPlayerMapped = computed(() => includes(playerSetupMappedPlayersForProjections.value, data.value.playerId));

    return {
      footballId,
      basketballId,
      baseballId: BASEBALL_ID,
      data,
      playerName,
      isAddMarketsModalActive,
      playerHasMarkets,
      availableMarkets,
      isPlayerVoid,
      hasSubmittedMarkets,
      marketsInStore,
      isPlayerSelectedForMarketsModal,
      playerMarkets,
      playerStatsActive,
      isPlayerHighlighted,
      isPlayerMarketActionsDisabled,
      isPricesReadonly,
      onModalClose,
      addMarkets,
      toggleMarketSuspend,
      togglePlayerSuspend,
      onAddSelectedMarkets,
      showMarketOverSelection,
      showMarketYesSelection,
      getMarketOverSelection,
      getMarketYesSelection,
      getMainLine,
      showMarketPrices,
      getScoreIndex,
      showYesSelectionMarketPrices,
      showPointBands,
      getPointBands,
      getSelectionPrice,
      togglePlayerForMarketsModal,
      togglePlayerStatsActive,
      updateMainLine,
      updateMarketOverUnderSelection,
      updatePointBandSelection,
      updateMarketYesNoSelection,
      isOverSelectionError,
      isMarketYesSelectionError,
      isPlayerMapped,
    };
  },
};
</script>

<style lang="scss">
  .markets-per-player-single {
    border: 2px solid transparent;
    &.player-highlighted {
      border: 2px solid $primary200;
      border-radius: 4px;
    }
    .info-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px;
        min-height: 52px;
        position: relative;

        .markets-per-player-actions-disabled-layer {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(255, 255, 255, .5);
          z-index: 1;
        }

        .player-info {
            display: flex;
            align-items: center;
            gap: 4px;
            font-size: 16px;
            line-height: 19px;
            font-weight: 600;
            cursor: pointer;

            .tooltip-text {
              font-weight: 400;
            }

            .tooltip-element {
              font-size: 14px;
              display: flex;

              .icon {
                &.projections-mapping-icon {
                  width: 16px;
                  height: 16px;
                  position: relative;
                  top: 1px;

                  svg {
                    path {
                      fill: #ff2e2d;
                    }
                  }

                  &.is-mapped {
                    svg {
                      path {
                        fill: #191414;;
                      }
                    }
                  }
                }
              }
            }
        }
        .player-actions {
          display: flex;
          justify-content: flex-end;
          gap: 5px;

          .player-actions-btn {
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 5px 10px;
            cursor: pointer;

            svg path {
              stroke: $black;
            }

            &.suspend-markets__active {
              .icon svg path {
                fill: $error500;
                stroke: $error500;
              }
              p {
                color: $error500;
              }

            }
          }
        }
    }
    .markets-data {
      padding: 0 16px;
      border-bottom: 1px solid $gray400;
        .markets-per-player-list {
          display: flex;
          flex-wrap: wrap;
          align-items: stretch;
          gap: 30px;
          padding: 32px 0;
          max-width: 100%;
          overflow: hidden;

          .market-item {
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            flex-wrap: wrap;
            gap: 6px;
            padding: 4px;
            border-radius: 4px;
            flex: 0 0 auto;

            .market-info {
              display: flex;
              justify-content: space-between;
              align-items: center;
              gap: 13px;
            }

            .market-prices {
              width: 100%;
              display: flex;
              align-items: center;
            }
            .point-bands {
              display: flex;
              align-items: flex-end;
              flex-wrap: nowrap;

              .point-band {
                display: flex;
                flex-direction: column;

                .point-band-label {
                  font-size: 10px;
                  color: #6D6D6D;
                }

              }
            }

            .market-price,
            .point-band {
              .number-input {
                input {
                  width: 48px;
                  height: 33px;
                  padding: 0 4px;
                  text-align: center;
                  font-size: 14px;
                  border-radius: 0;
                  border-right-width: 0;
                  position: relative;
                  z-index: 1;

                  &:focus {
                    z-index: 2;
                  }
                }
              }
              &:first-child {
                .number-input input {
                  border-top-left-radius: 4px;
                  border-bottom-left-radius: 4px;
                }
              }
              &:last-child {
                .number-input input {
                  border-top-right-radius: 4px;
                  border-bottom-right-radius: 4px;
                  border-right-width: 1px;
                }
              }
            }

            &.suspended {
              background-color: $error50;
            }

            &.void-player-market {
              background-color: $gray400;
            }

            .suspend-btn {
              width: 24px;
              height: 24px;
              min-width: 24px;
              min-height: 24px;
              border: 1px solid $gray400;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: $white;
              cursor: pointer;

              &.suspend-active {
                .icon svg path {
                  fill: $error500;
                }
              }
            }
          }
        }
        .no-data-message {
            width: 55%;
            margin: 0 auto;
            text-align: center;
            padding: 32px 0;
            p {
                line-height: 17px;
                text-align: center;
                color: $gray700;
            }
            .button {
                margin-top: 16px;
                span {
                    font-size: 14px;
                    line-height: 17px;
                }
            }
        }
    }
  }
</style>
